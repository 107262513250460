import axios from 'axios';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function createSurveySchema({ name, currentClinicId, isModel, isAppModel }) {
  try {
    const response = await axios.post(
      `/clinics/${currentClinicId}/survey_schemas`,
      { survey_schema: { is_model: isModel, name, is_app_model: isAppModel } },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json;charset=UTF-8' } },
    );
    return { ok: true, response };
  } catch (error) {
    return { ok: false, error: error.message, response: error.response };
  }
}

export async function duplicateSurveySchema({ name, currentClinicId, isModel, isAppModel, surveySchemaId }) {
  try {
    const response = await axios.post(
      `/clinics/${currentClinicId}/survey_schemas/${surveySchemaId}/duplicate`,
      { survey_schema: { is_model: isModel, name, is_app_model: isAppModel } },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json;charset=UTF-8' } },
    );
    if (response.status === 200) {
      return { ok: true, response };
    } else {
      return { ok: false, error: response.data.error, response };
    }
  } catch (error) {
    return { ok: false, error: error.response?.data?.error || error.message, response: error.response };
  }
}

export async function updateSurveySchema({ surveySchemaId, params, currentClinicId }) {
  try {
    const response = await axios.put(
      `/clinics/${currentClinicId}/survey_schemas/${surveySchemaId}`,
      { survey_schema: params },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json;charset=UTF-8' } },
    );
    return { ok: true, response };
  } catch (error) {
    return { ok: false, error: error.message, response: error.response };
  }
}

export async function publishSurveySchema({ surveySchemaId, selectedClinicId, currentClinicId }) {
  try {
    const response = await axios.put(
      `/clinics/${currentClinicId}/survey_schemas/${surveySchemaId}/publish`,
      { selected_clinic_id: selectedClinicId },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json;charset=UTF-8' } },
    );
    return { ok: true, response };
  } catch (error) {
    return { ok: false, error: error.message, response: error.response };
  }
}

export async function deleteSurveySchema(id) {
  try {
    const response = await axios.delete(`/survey_schemas/${id}`, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
    });
    return { ok: true, response };
  } catch (error) {
    return { ok: false, error: error.message, response: error.response };
  }
}

export async function uploadFiles({ formData, currentClinicId, surveySchemaId }) {
  try {
    const response = await axios.post(`/clinics/${currentClinicId}/survey_schemas/${surveySchemaId}/upload_file`, formData, {
      headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    });
    if (response.status === 200) {
      return { ok: true, file: response.data.file, message: response.data.message, response };
    } else {
      return { ok: false, error: response.data.error, response };
    }
  } catch (error) {
    return { ok: false, error: error.response?.data?.error || error.message, response: error.response };
  }
}

export async function saveReviewSurveyResult({ result, reviewUid }) {
  try {
    const response = await axios.post(
      `/reviews/${reviewUid}/survey_results`,
      { result },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json;charset=UTF-8' } },
    );
    console.log(response);
    if (response.status === 200) {
      return { ok: true, response };
    } else {
      return { ok: false, error: response.data.error, response };
    }
  } catch (error) {
    return { ok: false, error: error.response?.data?.error || error.message, response: error.response };
  }
}

export async function loadSurveyResults({ surveySchemaId }) {
  try {
    const response = await axios.get(`/survey_schemas/${surveySchemaId}/survey_results`, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
    });
    console.log(response);
    if (response.status === 200) {
      return { ok: true, response, surveyResults: response.data.survey_results };
    } else {
      return { ok: false, error: response.data.error, response };
    }
  } catch (error) {
    return { ok: false, error: error.response?.data?.error || error.message, response: error.response };
  }
}
